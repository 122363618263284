import Button from "@/components/common/buttons/button";
import { FormattedMessage } from "react-intl";

export default function CompletedPage({ reset }: { reset: () => void }) {
    return (
        <div className="h-full flex flex-col">
            <div className="h-full flex items-center">
                <div className="flex">
                    <div>
                        <h3 className="text-2xl font-medium">
                            <FormattedMessage id="Thank you for your participation" />
                        </h3>
                        <p className="text-gray-500 text-sm pt-2">
                            <FormattedMessage id="You can close this poll now" />
                        </p>
                    </div>
                    <div className="text-6xl">🎉</div>
                </div>
            </div>
            <Button
                id="back"
                color="voice"
                className="w-full justify-center"
                onClick={reset}
            >
                <FormattedMessage id="Close" />
            </Button>
        </div>
    );
}
